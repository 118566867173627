<template>
  <a-modal
    v-model:visible="modelVisible"
    :footer="null"
    :closable="false"
    width="630px"
    @cancel="close"
  >
    <div class="logModel">
      <div class="log-model-content">
        <a-empty style="margin-top: 40px" v-show="logList.length == 0" />
        <div class="logItem" v-for="(item, index) in logList" :key="index">
          {{ item }}
        </div>
      </div>
      <div class="controller">
        <button class="log-btn" @click="downLog">下载完整日志</button>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { mapGetters } from "vuex";
import { formateSeconds } from "@/utils/tool";
export default {
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
    current: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      modelVisible: false,
      formateSeconds,
      logList: [],
    };
  },
  methods: {
    async getLogData() {
      try {
        let res = await this.$http.get(
          this.current.type == "caseLog"
            ? this.$interface.caseLogList + `?memberCaseId=${this.current.memberCaseId}`
            : this.$interface.caseWrongCaseLog +
                `?memberCaseId=${this.current.memberCaseId}`
        );
        let data = res.data.data || [];
        this.logList = data;
      } catch (err) {
        this.logList = [];
      }
    },
    async downLog() {
      if (this.logList.length == 0) {
        this.$message.info("暂无日志数据,请操作后下载");
        return;
      }
      let array = [["日志"]];
      try {
        this.logList.forEach((d) => {
          array.push([d]);
        });
        let ws = XLSX.utils.aoa_to_sheet(array);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          wb,
          ws,
          this.current.type == "caseLog" ? "病例日志" : "错误日志"
        );
        XLSX.writeFile(
          wb,
          this.current.type == "caseLog" ? "病例日志.xlsx" : "错误日志.xlsx"
        );
      } catch (err) {
      } finally {
      }
    },
    close() {
      this.$emit("update:visible", false);
    },
  },
  watch: {
    current(newV, oldV) {
      this.getLogData();
    },
    visible(newV, oldV) {
      this.modelVisible = newV;
    },
  },
  computed: {
    ...mapGetters(["caseLog"]),
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>
